import React from 'react';
import {Spin} from 'antd';

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column'
      }}
    >
      <img
        style={{width: '100px', opacity: 0.5, marginBottom: '1rem'}}
        src={`${process.env.PUBLIC_URL}/images/logo-small.png`}
        alt=""
      />
      <Spin size="small" tip="Caricamento in corso..." />
    </div>
  );
};

export default Loading;
